@font-face {
  font-family: 'Noteworthy Light';
  font-style: normal;
  font-weight: normal;
  src: local('Noteworthy Light'), url('./../Fonts/Noteworthy-Lt.woff') format('woff');
  }


html 
body{ 
  background: #282c34;
  color: white;
}

.MuiStepLabel-label{
  color: white;
}

.MuiTypography-root{
  color: white;
}

.App {
  text-align: center;
  font-family: 'Noteworthy Light';
}

._53Ji7{
  font-family: 'Noteworthy Light';
}
  
.MobileApp {
  height: 70vh;
  margin-top: 10%;
  align-items: center;
  direction: ltr;
}
.AppTitle {
  margin-bottom: 0;
  color:#F94CCB;
}

.PhoneControl {      
  margin-top: 10pt;
  margin-bottom: 5pt;
}

.AppSecondTitle{
  color:#FDA23E;
}

.inputs {   
  width: 100%;
  justify-content: center;
  display: flex;
  align-content: center;
  align-items: center;
  
}

._2Jtxm::before {
  right: auto;  
  left: 50% !important;
}

.termsAndCond {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}